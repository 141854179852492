const skills = [
  {
    id: 1,
    title: "Back End",
    image: "backend_blue.png",
    languages: [
      {
        id: 1,
        title: "Java",
        css: "java",
      },
      {
        id: 2,
        title: "Groovy",
        css: "groovy",
      },
      {
        id: 3,
        title: "Apache Solr",
        css: "solr",
      },
      {
        id: 4,
        title: "Spring Data",
        css: "spring",
      },
      {
        id: 5,
        title: "Docker",
        css: "docker",
      },
    ],
    description:
      "I develop web apps using JVM languages and work closely with NoSQL search engines like Apache Solr",
  },
  {
    id: 2,
    title: "Front End",
    image: "frontend_blue.png",
    languages: [
      {
        id: 1,
        title: "JavaScript",
        css: "js",
      },
      {
        id: 2,
        title: "Vue.js",
        css: "vuejs",
      },
      {
        id: 3,
        title: "Bootstrap",
        css: "bootstrap",
      },
      {
        id: 4,
        title: "Bulma",
        css: "bulma",
      },
    ],
    description:
      "Even though Im a backend developer, I have passion for designing web apps and websites and Im into such things as JavaScript and Vue.js",
  },
  {
    id: 3,
    title: "Machine Learning",
    image: "ai_blue.png",
    languages: [
      {
        id: 1,
        title: "Tensorflow",
        css: "tensorflow",
      },
      {
        id: 2,
        title: "PyTorch",
        css: "pytorch",
      },
      {
        id: 3,
        title: "OpenCV",
        css: "opencv",
      },
    ],
    description:
      "I use deep learning to solve problems in the real world. I use Tensorflow and PyTorch to build neural networks and use OpenCV to process images",
  },
];

export default skills;
