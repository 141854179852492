<template>
  <!-- Menu -->
  <MenuVue :links="links">> # ~ / gavyla<span class="prim">u</span></MenuVue>
  <HeroSection id="hero" pic="header.png">
    <template v-slot:welcome>Welcome!</template>
    My name is <b>Gavy Lau</b> and I'm a
    <hi-word>software engineer and architect</hi-word>. My passion is building
    <hi-word>web applications</hi-word> and
    <hi-word>distributed system</hi-word> using modern frameworks. <br /><br />I
    have extensive experience in the wealth management and portfolio analytics
    financial domain
    <template v-slot:buttons>
      <simple-button
        class="btn-primary text-white"
        url="https://www.linkedin.com/in/gavylau/"
        icon="fab linkedin-in"
        >Find me!</simple-button
      >
      <simple-button
        class="btn-outline-secondary"
        url="mailto:gavylau@gmail.com"
        icon="fa-regular fa-file-lines"
        >Contact me</simple-button
      >
    </template>
  </HeroSection>
  <SkillsGrid id="skills" :skills="skills" />
  <ExperienceTable id="edu-exp" :experience="experience" />
  <FooterVue :links="socials" :copyright="copyright" />
</template>
<script>
import MenuVue from "@/components/shared/AppMenu.vue";
import FooterVue from "@/components/shared/AppFooter.vue";
import HeroSection from "@/components/hero/HeroSection.vue";
import SkillsGrid from "@/components/skills/SkillsGrid.vue";
import ExperienceTable from "@/components/experience/ExperienceTable.vue";

// data to fill components
import skills from "@/data/skills";
import experience from "@/data/experience";

import SimpleButton from "@/components/reusable/SimpleButton.vue";
import HiWord from "@/components/reusable/HighlightIt.vue";

export default {
  name: "HomeView",
  data: () => {
    return {
      delay: 100,
      links: [
        {
          name: "home",
          url: "#hero",
        },
        {
          name: "skills",
          url: "#skills",
        },
        {
          name: "experience",
          url: "#edu-exp",
        },
      ],
      skills,
      experience,
      socials: [
        {
          id: 1,
          icon: "fab linkedin-in",
          url: "https://www.linkedin.com/in/gavylau/",
        },
        {
          id: 2,
          icon: "fab github",
          url: "https://github.com/gavylau",
        },
      ],
      copyright: "2023 Gavy Lau",
    };
  },
  components: {
    MenuVue,
    FooterVue,
    HeroSection,
    SkillsGrid,
    ExperienceTable,
    SimpleButton,
    HiWord,
  },
};
</script>
<style lang="scss" scoped>
.prim {
  color: $primary;
}
</style>
