const experience = [
  {
    id: 1,
    date: "Jan 2020 - Present",
    position: "Principal Software Engineer/Architect",
    company: "Privé Technologies",
    url: "https://privetechnologies.com/",
    description: [
      {
        id: 1,
        description:
          "Architect and design the next generation of Privé Wealth Platform",
      },
      {
        id: 2,
        description:
          "Design and implement the technology direction of the platform",
      },
      {
        id: 3,
        description:
          "Responsible for the overall architecture and design of the platform",
      },
    ],
  },
  {
    id: 2,
    date: "Jul 2012 - Jan 2020",
    position: "Head of Software Engineering",
    company: "Privé Technologies",
    url: "https://privetechnologies.com/",
    description: [
      {
        id: 1,
        description: "Build the SaaS Prive Wealth Platform from ground up",
      },
      {
        id: 2,
        description:
          "Responsible all technology related aspect of the system, from architecture, design, development, testing, deployment, and maintenance",
      },
    ],
  },
  {
    id: 3,
    date: "Mar 2005 - Nov 2007",
    position: "Developer",
    company: "Barclay Capital",
    url: "https://www.cib.barclays/",
    description: [
      {
        id: 1,
        description:
          "Work in the Global Marketing Event and Roadshow Management System",
      },
      {
        id: 2,
        description:
          "Involves in the both frontend and backend development of the system",
      },
      {
        id: 2,
        description:
          "Involves in numerous refactoring and performance tuning of the system",
      },
    ],
  },
];

export default experience;
